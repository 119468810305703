import { computePathFromCategory } from 'ic-js-content'
import { useContentStore } from '~/stores/content'
export async function useContentCard (props) {
	const { categories, initContentStore } = useContentStore()
	const config = useRuntimeConfig()
	await initContentStore()

	const image = computed(() => {
		const src = props?.content?.heroMedia?.content?.imageThumbnail ||
			props?.content?.heroMedia?.content?.videoThumbnail ||
			props?.content?.heroMedia?.content?.image ||
			''
		const alt = props.content?.heroMedia?.content?.altText || props.content?.heroMedia?.title || ''

		return { src, alt }
	})

	const cardHovered = ref(false)

	const fullCategory = categories.find(category => category.id === props?.content?.displayCategory?.id)

	const articleLink = computed(() => {
		if (!props.content?.singlePage) return `/content/${props?.content?.slug}`
		if (!fullCategory) return `/${props?.content?.slug}`
		if (config.public.functionalCategorySlugs.includes(fullCategory.slug)) return `/${props.content?.slug}`
		if (fullCategory?.id === config.public.categoryIds.external) return props?.content?.heroMedia?.content?.link
		return `${computePathFromCategory(categories, fullCategory)}/${props?.content?.slug}`
	})

	const publishDate = computed(() => {
		return useDateFormat(new Date(props?.content?.publishDate), 'DD MMM').value
	})

	const duration = computed(() => {
		let msDuration = props?.content?.heroMedia?.content?.metadata?.msDuration

		if (!msDuration) {
			if (props?.content?.readTimeMinutes) {
				msDuration = props?.content?.readTimeMinutes * 1000
			} else {
				return null
			}
		}

		const hours = Math.floor(msDuration / 3600000).toString().padStart(2, '0')
		const minutes = Math.floor((msDuration % 3600000) / 60000).toString().padStart(2, '0')
		const seconds = Math.floor(((msDuration % 360000) % 60000) / 1000).toString().padStart(2, '0')
		return `${+hours ? `${hours}:` : ''}${minutes}:${seconds}`
	})

	return {
		image,
		fullCategory,
		cardHovered,
		articleLink,
		publishDate,
		duration,
	}
}
